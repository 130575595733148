import "@scss/main.scss"
import "picturefill"
import "lazysizes"
import SmoothScroll from 'smooth-scroll'
import Splitting from 'splitting'
import { gsap, TimelineLite, Power2 } from 'gsap'

import Accordino from './libs/Accordino'

import HomeRenderer from './renderers/HomeRenderer'
import PageRenderer from './renderers/PageRenderer'

window.onload = function(){

    // Render
    let renderer = false
    switch (document.querySelector('.o-main').dataset.type) {
        case 'home':
            renderer = new HomeRenderer
            break;
        default:
            renderer = new PageRenderer
            break;
    }
	renderer.onEnter()

	// Top anim
	// Title
	const title = Splitting({
		target: '.o-top .a-heading--1'
	})
	// Text
	const text = Splitting({
		target: '.o-top .a-text',
		by: 'lines'
	})
	// Timeline
	const timeline = new TimelineLite()
	title[0].words.forEach((word, i) => {
		timeline.from(word, { 
			opacity: 0, 
			y: window.innerWidth < 1024 ? 20 : 40,
			duration: 0.5,
			ease: Power2.easeOut
		}, i == 0 ? '0' : '-=0.425')
	})
	if(text.length > 0) {
		text[0].lines.forEach((line, i) => {
			timeline.addLabel(`line-${i}`, i == 0 ? '-=0.2' : '-=0.4')
			line.forEach(word => {
				timeline.from(word, { 
					opacity: 0, 
					y: window.innerWidth < 1024 ? 10 : 20,
					duration: 0.5,
					ease: Power2.easeOut
				}, `line-${i}`)
			})
		})
	}
	if(document.querySelector('.o-top .a-btn')) {
		timeline.to(document.querySelector('.o-top .a-btn'), { 
			opacity: 1,
			duration: 0.5
		}, '-=0.25')
	}
	if(document.querySelector('.o-top .o-top__categories')) {
		timeline.to(document.querySelector('.o-top .o-top__categories'), { 
			opacity: 1,
			duration: 0.5
		}, '-=0.25')
	}

	if (window.innerWidth < 1024) {
		// Menu open
		document.querySelector('.a-burger').addEventListener('click', () => {
			document.body.classList.toggle('o-body--menu-on')
		})
		document.querySelector('.o-body__mask').addEventListener('click', () => {
			document.body.classList.remove('o-body--menu-on')
		})
		// Menu accordino
		const items = document.querySelectorAll('.m-nav-main__item--expanded')
		items.forEach(item => {
			new Accordino(item,{
				question: item.querySelector('.m-nav-main__link'),
				answer: item.querySelector('.m-nav-main__sub'),
				duration: 300
			})
		})
	}
	
	// Header on scroll
	const longScroll = window.innerHeight / 2
    window.addEventListener('scroll', e => {
        if(window.scrollY > 40 && !document.body.classList.contains('o-body--scrolled')) {
            document.body.classList.add('o-body--scrolled')
        } else if(window.scrollY <= 40) {
            document.body.classList.remove('o-body--scrolled')
        }
        if(window.scrollY > longScroll && !document.body.classList.contains('o-body--long-scrolled')) {
            document.body.classList.add('o-body--long-scrolled')
        } else if(window.scrollY <= longScroll) {
            document.body.classList.remove('o-body--long-scrolled')
        }
	})
	
	// Smoothscroll links
	new SmoothScroll('a[href*="#"]', {
		header: window.innerWidth < 1024 ? false : 'header',
		offset: 40
	})

	// // UTM to cookies
	// function getParameter(theParameter) {
	//   var params = window.location.search.substr(1).split('&');
	//   for (var i = 0; i < params.length; i++) {
	//     var p=params[i].split('=');
	//      if (p[0] == theParameter) {
	//       return decodeURIComponent(p[1]);
	//     }
	//   }
	//   return false;
	// }

	// function getCookie(cname) {
	//   var name = cname + "=";
	//   var decodedCookie = decodeURIComponent(document.cookie);
	//   var ca = decodedCookie.split(';');
	//   for(var i = 0; i <ca.length; i++) {
	//     var c = ca[i];
	//     while (c.charAt(0) == ' ') {
	//       c = c.substring(1);
	//     }
	//     if (c.indexOf(name) == 0) {
	//       return c.substring(name.length, c.length);
	//     }
	//   }
	//   return "";
	// }

	// const url_src = getParameter('utm_source');
	// if(url_src) {
	// 	document.cookie = 'utm_source=' + url_src; + 'expires=3600path = /';
	// }
	// var els=document.getElementsByName("utm_source");
	// for (var i=0;i<els.length;i++) {
	// 	els[i].value = getCookie('utm_source');
	// }

	// const url_mdm = getParameter('utm_medium');
	// if(url_mdm) {
	// 	document.cookie = 'utm_medium=' + url_mdm; + 'expires=3600path = /';
	// }
	// var els=document.getElementsByName("utm_medium");
	// for (var i=0;i<els.length;i++) {
	// 	els[i].value = getCookie('utm_medium');
	// }

	// const url_cpn = getParameter('utm_campaign');
	// if(url_cpn) {
	// 	document.cookie = 'utm_campaign=' + url_cpn; + 'expires=3600path = /';
	// }
	// var els=document.getElementsByName("utm_campaign");
	// for (var i=0;i<els.length;i++) {
	// 	els[i].value = getCookie('utm_campaign');
	// }
	if(document.querySelector('.m-nav-legal')) {
		document.querySelector('.m-nav-legal__item:nth-child(3n) a').addEventListener('click', e => {
			e.preventDefault()
			cookizi.showSidebar()
		})
	}

}

// SWPL LOG
console.log(
	"%c S W P L ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`,
	" www.swpl.fr"
);
