import { gsap, TimelineLite, Power2 } from 'gsap'

export default class Leaves {
    constructor(el) {
        // Check element
        if(el instanceof HTMLElement) {
            this.el = el
        } else {
            this.el = document.querySelector(el)
        }
        if(!this.el) {
            console.warn('No valid leaves found. Sorry bro.')
            return false
        }
        // Init leaves
        this.timeline = new TimelineLite({
            paused: true
        })
        this.leaves = this.el.querySelectorAll('.a-leaves__leaf')
        this.initTimeline()
    }
    initTimeline() {
        // Iterate over leaves
        this.leaves.forEach(leaf => {
            const position = this.getRandomPosition()
            this.timeline.to(leaf, {
                top: `${position.x}%`,
                left: `${position.y}%`,
                opacity: 1,
                rotation: this.getRandomDegree(),
                duration: this.getRandomTime(),
                ease: Power2.easeOut
            }, 0)
        })
    }
    start() {
        this.timeline.restart()
    }
    restart() {
        // Iterate over leaves to set initial pos
        this.leaves.forEach(leaf => {
            gsap.set(leaf, {
                top: '50%',
                left: '50%',
                opacity: 0,
                rotation: 0,
                duration: 0
            }, 0)
        })
        // Reinit timeline
        this.timeline.kill()
        this.timeline = new TimelineLite({
            paused: true
        })
        this.initTimeline()
        this.timeline.restart()
    }
    getRandomDegree() {
        return Math.round(Math.random() * 360)
    }
    getRandomTime() {
        return Math.round((Math.random() + 1) * 10) / 10
    }
    getRandomPosition() {
        return {
            x: Math.round(Math.random() * 100),
            y: Math.round(Math.random() * 100)
        }
    }
}