import Map from '../libs/Map'
import Feedizi from '../libs/Feedizi'
import Leaves from '../libs/Leaves'
import Swipino from '../libs/Swipino'
import Accordino from '../libs/Accordino'

import sal from 'sal.js'
import Swiper from 'swiper'

export default class PageRenderer {
    constructor() {
    }

    onEnter() {
        // leaves
        sal({
            threshold: '0.1'
        })
        // if(document.querySelectorAll('.a-leaves')) {
        //     document.querySelectorAll('.a-leaves').forEach(leaves => {
        //         const leavizi = new Leaves(leaves)
        //         leaves.addEventListener('sal:in', () => {
        //             leavizi.start()
        //         })
        //     })
        // }

        // Jobs leaves
        if(document.querySelectorAll('.m-view-jobs_list__leaves') && window.innerWidth >= 1280) {
            document.querySelectorAll('.m-view-jobs_list__row').forEach(job => {
                const leavizi = new Leaves(job.querySelector('.a-leaves'))
                let first = true
                job.addEventListener('mouseenter', () => {
                    if(!first) {
                        leavizi.restart()
                    } else {
                        leavizi.start()
                        first = false
                    }
                })
            })
        }

        // Stats leaves
        if(document.querySelectorAll('.m-stats__leaves') && window.innerWidth >= 1280) {
            document.querySelectorAll('.m-stats__item').forEach(stat => {
                const leavizi = new Leaves(stat.querySelector('.a-leaves'))
                let first = true
                stat.addEventListener('mouseenter', () => {
                    if(!first) {
                        leavizi.restart()
                    } else {
                        leavizi.start()
                        first = false
                    }
                })
            })
        }

        // CTA leaves
        if(document.querySelectorAll('.o-block--button') && window.innerWidth >= 1280) {
            document.querySelectorAll('.o-block--button').forEach(block => {
                const leavizi = new Leaves(block.querySelector('.a-leaves'))
                let first = true
                block.querySelector('.a-btn').addEventListener('mouseenter', () => {
                    if(!first) {
                        leavizi.restart()
                    } else {
                        leavizi.start()
                        first = false
                    }
                    block.classList.add('o-block--active')
                })
                block.querySelector('.a-btn').addEventListener('mouseleave', () => {
                    block.classList.remove('o-block--active')
                })
            })
        }

        // Feedizi on views
        if(document.querySelector('.m-view-news')) {
            new Feedizi('.m-view-news')
        }

        // Companies map
        if(document.querySelector('.m-map')) {
            new Map('.m-map')
        }

        // Missions
        if(document.querySelector('.m-missions') && window.innerWidth < 768) {
            new Swipino('.m-missions', {
                slideClass: 'm-missions__mission',
                options: {
                    speed: 500,
                    spaceBetween: 40,
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    centeredSlides: true,
                }
            })
        }  

        // Logos
        if(document.querySelector('.m-logos') && window.innerWidth < 1024) {
            new Swipino('.m-logos', {
                slideClass: 'm-logos__logo',
                options: {
                    speed: 500,
                    spaceBetween: 40,
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    centeredSlides: true,
                    loop: true
                }
            })
        }  

        // Team
        if(document.querySelector('.m-team') && window.innerWidth < 1024) {
            document.querySelectorAll('.m-team').forEach(team => {
                new Swipino(team, {
                    slideClass: 'm-team__member',
                    options: {
                        speed: 500,
                        spaceBetween: 20,
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        slideToClickedSlide: true,
                        breakpoints: {
                            768: {
                                centeredSlides: false
                            }
                        }
                    }
                })
            })
        }

        // Team
        if(document.querySelector('.m-view-jobs_list__container') && window.innerWidth < 768) {
            document.querySelectorAll('.m-view-jobs_list__container').forEach(jobList => {
                new Swipino(jobList, {
                    slideClass: 'm-view-jobs_list__row',
                    options: {
                        speed: 500,
                        spaceBetween: 20,
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        slideToClickedSlide: true,
                    }
                })
            })
        }

        // Text lists
        if(document.querySelector('.m-list')) {
            if(window.innerWidth < 1024) {
                const items = document.querySelectorAll('.m-list__item')
                items.forEach(item => {
                    new Accordino(item,{
                        question: item.querySelector('.m-list__question'),
                        answer: item.querySelector('.m-list__answer'),
                        duration: 300
                    })
                })
            } else {
                const items = new Swiper('.m-list__items', {
                    speed: 250,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    init: false
                })
                items.on('init', swiper => {
                    let counter = 0
                    swiper.slides.forEach(slide => {
                        // Create titles
                        const title = document.createElement('span')
                        title.classList.add('m-list__title')
                        if(counter == 0)
                            title.classList.add('m-list__title--active')
                        title.innerHTML = slide.dataset.title
                        title.dataset.id = counter
                        document.querySelector('.m-list__titles').append(title)
                        // Listener
                        title.addEventListener('click', () => {
                            items.slideTo(title.dataset.id)
                        })
                        counter++
                    })
                })
                items.on('slideChange', swiper => {
                    document.querySelector('.m-list__title--active').classList.remove('m-list__title--active')
                    document.querySelector(`.m-list__title[data-id="${swiper.realIndex}"]`).classList.add('m-list__title--active')
                })
                items.init()
            }
        }
    }
}