import Swipino from '../libs/Swipino'
import Leaves from '../libs/Leaves'

import sal from 'sal.js'
        
// TODO : Destroy or create swipers on resize
export default class HomeRenderer {
    onEnter() {
        // leaves
        sal({
            threshold: 0.15
        })
        // if(document.querySelectorAll('.a-leaves')) {
        //     document.querySelectorAll('.a-leaves').forEach(leaves => {
        //         const leavizi = new Leaves(leaves)
        //         leaves.addEventListener('sal:in', () => {
        //             leavizi.start()
        //         })
        //     })
        // }

        // Teasings leaves
        if(document.querySelectorAll('.m-teasings__leaves') && window.innerWidth >= 1280) {
            document.querySelectorAll('.m-teasings--double .m-teasings__teasing').forEach(teasing => {
                const leavizi = new Leaves(teasing.querySelector('.a-leaves'))
                let first = true
                teasing.addEventListener('mouseenter', () => {
                    if(!first) {
                        leavizi.restart()
                    } else {
                        leavizi.start()
                        first = false
                    }
                })
            })
        }

        // CTA leaves
        if(document.querySelectorAll('.o-block--button') && window.innerWidth >= 1280) {
            document.querySelectorAll('.o-block--button').forEach(block => {
                const leavizi = new Leaves(block.querySelector('.a-leaves'))
                let first = true
                block.querySelector('.a-btn').addEventListener('mouseenter', () => {
                    if(!first) {
                        leavizi.restart()
                    } else {
                        leavizi.start()
                        first = false
                    }
                    block.classList.add('o-block--active')
                })
                block.querySelector('.a-btn').addEventListener('mouseleave', () => {
                    block.classList.remove('o-block--active')
                })
            })
        }

        if(document.querySelector('.m-view-news') && window.innerWidth < 768) {
            new Swipino('.m-view-news', {
                options: {
                    speed: 500,
                    spaceBetween: 40,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    slideToClickedSlide: true,
                    loop: true
                }
            })
        }
        if(document.querySelector('.m-cards') && window.innerWidth < 1024) {
            new Swipino('.m-cards', {
                slideClass: 'm-cards__card',
                options: {
                    speed: 500,
                    spaceBetween: 20,
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    centeredSlides: true,
                    breakpoints: {
                        768: {
                            centeredSlides: false
                        }
                    }
                }
            })
        }     
    }
}