import Swiper from 'swiper/bundle'

export default class GMAPino {
    constructor(el, props) {
        // Check element
        if(el instanceof HTMLElement) {
            this.el = el
        } else {
            this.el = document.querySelector(el)
        }
        if(!this.el) {
            console.warn('No valid swipino found. Sorry bro.')
            return false
        }
        
        // Props
        this.options = props.options
        this.class = this.el.className

        // Get class names
        this.wrapperClass = void 0 !== props.wrapperClass ? props.wrapperClass : `${this.class}__rows`
        this.slideClass = void 0 !== props.slideClass ? props.slideClass : `${this.class}__row`

        // Add classes to DOM
        this.addClasses()

        // Init swiper
        this.init()
    }

    addClasses() {
        // Container
        this.el.classList.add('swiper-container')

        // Wrapper
        this.wrapper = this.el.querySelector(`.${this.wrapperClass}`)
        if(!this.wrapper) {
            // If there's no wrapper, wrap slides
            this.wrapper = document.createElement('div')
            this.wrapper.classList.add('swiper-wrapper')
            this.wrapper.innerHTML = this.el.innerHTML
            this.el.innerHTML = ''
            this.el.append(this.wrapper)
        } else {
            this.wrapper.classList.add('swiper-wrapper')
        }

        // Slides
        this.slides = this.el.querySelectorAll(`.${this.slideClass}`)
        console.log(this.slides)
        this.slides.forEach(slide => {
            slide.classList.add('swiper-slide')
            slide.style.opacity = 1
            slide.style.transform = 'none'
            slide.dataset.sal = ''
        })
    }

    init() {
        this.swiper = new Swiper(this.el, this.options)
    }

    destroy() {
        // Destroy swiper
        this.swiper.destroy()

        // Remove classes
        this.el.classList.remove('swiper-container')
        this.wrapper.classList.remove('swiper-wrapper')
        this.slides.forEach(slide => {
            slide.classList.remove('swiper-slide')
        })
    }
}