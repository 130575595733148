export default class Feedizi {
    constructor(el) {
        // Check view element
        if(el instanceof HTMLElement) {
            this.el = el
        } else {
            this.el = document.querySelector(el)
        }
        if(!this.el || !this.el.querySelector('.views-exposed-form')) {
            console.warn('No valid view found. Sorry bro.')
            return false
        }
        // Init
        this.class = this.el.className
        this.form = this.el.querySelector('.views-exposed-form')
        this.tagsContainer = this.el.querySelector(`.${this.class}__tags`)
        this.rowsContainer = this.el.querySelector(`.${this.class}__rows`)
        this.formSelect = this.form.querySelector('.form-item select[multiple]')
        this.more = this.el.querySelector(`.${this.class}__more`)
        this.values = []
        this.page = 0
        // Create tags
        this.createTags()
        // Listen more
        this.listenMore()
    }
    createTags() {
        // Iterate over options
        const options = this.formSelect.querySelectorAll('option')
        options.forEach(option => {
            // Create tag link
            const tagEl = document.createElement('a')
            tagEl.href = '#'
            tagEl.innerHTML = option.innerHTML
            tagEl.dataset.value = option.value
            tagEl.classList.add(`${this.class}__tag`)
            this.tagsContainer.append(tagEl)
            // Listen tag events
            this.listenTag(tagEl)
        })
        // Create Reset tag
        const tagEl = document.createElement('a')
        tagEl.href = '#'
        tagEl.dataset.value = 'reset'
        tagEl.classList.add(`${this.class}__tag`, `${this.class}__tag--reset`)
        this.tagsContainer.append(tagEl)
        // Listen tag events
        this.listenTag(tagEl)
    }
    listenMore() {
        // Check if more is active on first paint
        if(this.el.dataset.more == undefined)
            this.more.classList.add(`${this.class}__more--inactive`)
        // More click
        this.more.addEventListener('click', e => {
            e.preventDefault()
            // Add loading button class
            this.more.classList.add(`${this.class}__more--loading`)
            // Get URL
            this.page++
            const url = this.getURL(this.page)
            // Append DOM
            this.appendDOM(url, false)
        })
    }
    listenTag(tagEl) {
        // Listen click
        tagEl.addEventListener('click', e => {
            e.preventDefault()
            // Add or remove from values
            if(this.values.indexOf(tagEl.dataset.value) != -1) {
                // Remove tag
                this.values.splice(this.values.indexOf(tagEl.dataset.value), 1)
                tagEl.classList.remove(`${this.class}__tag--active`)
            } else if(tagEl.dataset.value == 'reset') {
                // Reset all tag
                this.values = []
                // Remove active classes
                this.tagsContainer.querySelectorAll(`.${this.class}__tag--active`).forEach(tag => {
                    tag.classList.remove(`${this.class}__tag--active`)
                })
            } else {
                // Add tag
                this.values.push(tagEl.dataset.value)
                tagEl.classList.add(`${this.class}__tag--active`)
            }
            // Add loading tags class
            this.tagsContainer.classList.add(`${this.class}__tags--loading`)
            // Add loading rows class
            this.rowsContainer.classList.add(`${this.class}__rows--loading`)
            // Get URL
            const url = this.getURL()
            // Append DOM
            this.appendDOM(url, true)
            // Reset page
            this.page = 0
        })
    }
    getURL(page = false) {
        let params = ''
        this.values.forEach(value => {
            params += `&${this.formSelect.name}=${value}`
        })
        return `${this.form.action}?page=${page ? page : 0}${params}`
    }
    appendDOM(url, reset = false) {
        // Send request
        const request = new XMLHttpRequest()
        request.open('GET', url)
        request.responseType = 'document'
        request.send()
        request.onload = () => {
            const dom = request.response
            // Get DOM
            const rows = dom.querySelectorAll(`.${this.class}__row`)
            if(reset) {
                // FILTERED : Replace current rows
                this.rowsContainer.innerHTML = ''
                rows.forEach(row => {
                    row.dataset.sal = ''
                    this.rowsContainer.append(row)
                })
                // Remove loading tags class
                this.tagsContainer.classList.remove(`${this.class}__tags--loading`)
                // Add loading rows class
                this.rowsContainer.classList.remove(`${this.class}__rows--loading`)
            } else {
                // MORE LINK : Add to current rows
                rows.forEach(row => {
                    row.dataset.sal = ''
                    this.rowsContainer.append(row)
                })
                // Add loading button class
                this.more.classList.remove(`${this.class}__more--loading`)
            }
            // Hide more button or not
            if(dom.querySelector('[data-more]')) {
                this.more.classList.remove(`${this.class}__more--inactive`)
            } else {
                this.more.classList.add(`${this.class}__more--inactive`)
            }
        }
    }
}