import MarkerClusterer from '@google/markerclustererplus'

export default class Map {
    constructor(el) {
        // Check element
        if(el instanceof HTMLElement) {
            this.el = el
        } else {
            this.el = document.querySelector(el)
        }
        if(!this.el) {
            console.warn('No valid map found. Sorry bro.')
            return false
        }

        // Init
        this.markers = []
        this.markerClusterers = []
        this.departments = false
        this.clusterStyle = [{
            fontFamily: 'Open Sans',
            textSize: 18,
            textLineHeight: 70,
            fontWeight: '600',
            textColor: '#fff',
            url: '/sites/fnmj-site/themes/sdt/dist/img/cluster.png',
            height: 70,
            width: 60
        }]
        this.pagination = 9
        this.style = [{"featureType":"poi","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"road","stylers":[{"visibility":"off"}]},{"featureType":"water","stylers":[{"color":"#85b7d0"}]}]
        this.moreEl = document.querySelector('.m-view-adherents_list__more')
        this.departments = {}

        this.gMap()
        this.createValues()
        this.submit()
        this.more()
    }
    gMap() {
        // Create GMAP
        this.map = new google.maps.Map(this.el, {
            mapTypeControl: false,
            streetViewControl: false,
            styles: this.style,
            maxZoom: 17
        })
        // Repaint clusterers on zoom animation end to fix a visual issue on clusterers
        this.map.addListener('idle', () => {
            this.markerClusterers.forEach(markerClusterer => {
                markerClusterer.repaint()
            })
        })
        // Read json
        const request = new XMLHttpRequest()
        request.open('GET', '/sites/fnmj-site/files/api/map.json')
        request.responseType = 'json'
        request.send()
        const _this = this
        request.onload = () => {
            // Get departments
            _this.departments = request.response
            // Draw markers and clusterers
            _this.drawMap()
        }
    }
    drawMap() {
        // Create infowindow
        this.infoWindow = new google.maps.InfoWindow({ content: '' })
        this.map.addListener('click', () => {
            this.infoWindow.close()
        })
        // Create markers
        const bounds = new google.maps.LatLngBounds()
        Object.entries(this.departments).forEach(department => {
            // Shops
            // const departmentMarkers = []
            department[1].forEach(shop => {
                if(!this.isLatitude(Number(shop.latitude)) || !this.isLongitude(Number(shop.longitude)))
                    return false
                // // Return false if its filtered
                // if((departmentValue != 'All' && departmentValue != shop.field_department.id) || (partnerValue != 'All' && partnerValue != shop.field_company.id))
                //     return false
                // Extend bounds
                const position = {lat: Number(shop.latitude), lng: Number(shop.longitude)}
                const marker = new google.maps.Marker({
                    position: position,
                    department: shop.department,
                    company: shop.company,
                    icon: {
                        url: '/sites/fnmj-site/themes/sdt/dist/img/marker.png',
                        anchor: new google.maps.Point(15, 15)
                    }
                })
                // Add marker to map
                marker.setMap(this.map)
                // Extend bounds
                if(this.inFrance(position))
                    bounds.extend(position)
                // Push and return marker
                this.markers.push(marker)
                // departmentMarkers.push(marker)
                // Info window
                marker.addListener("click", () => {
                    // Show infowindow
                    this.infoWindow.setContent(  
                        `<div class="m-view-adherents_list__info-window">
                            <div class="m-view-adherents_list__info-visual-container">
                                <div class="m-view-adherents_list__info-visual">
                                    <img src="${shop.company.logo}" alt="${shop.company.name}"/>
                                </div>
                            </div>
                            <div class="m-view-adherents_list__info-content">
                                <div class="m-view-adherents_list__department">${shop.department}</div>
                                <h3 class="a-heading a-heading--3 a-heading--no-top">${shop.company.name}</h3>
                                <div class="a-text a-text--slim">
                                    ${shop.address}<br>
                                    ${shop.zip_code} ${shop.city}
                                </div>
                            </div>
                        </div>`
                    )
                    this.infoWindow.open(this.map, marker)
                    // // Zoom on point
                    // this.map.setCenter(marker.position)
                    // this.map.setZoom(17)
                })
                // console.log()
            })
            // // Create clusterer
            // const markerClusterer = new MarkerClusterer(this.map, departmentMarkers, {
            //     styles: this.clusterStyle,
            //     ignoreHidden: true
            // })
            // this.markerClusterers.push(markerClusterer)
        })
        // Create clusterer
        const markerClusterer = new MarkerClusterer(this.map, this.markers, {
            styles: this.clusterStyle,
            ignoreHidden: true
        })
        this.markerClusterers.push(markerClusterer)
        // Fit bounds
        this.map.fitBounds(bounds)
    } 
    inFrance(position) {
        if(position.lat > 42.17762253766909 && position.lat < 51.2503941870643 && position.lng > -5.001833306897424 && position.lng < 8.498304022204138) {
            return true
        } else {
            return false
        }
    }
    filterMap() {
        // Get inputs value
        const department = document.querySelector('input[list="departments"]').value
        const departmentValue = department == '' ? 'All' : department
        const partnerValue = document.querySelector('input[list="names"]').value == '' ? 'All' : document.querySelector('input[list="names"]').value
        
        // Create bounds
        var bounds = new google.maps.LatLngBounds()
        // Set visible or invisible markers
        var visible = false
        this.markers.forEach(marker => {

            if((departmentValue == 'All' || departmentValue == marker.department) && (partnerValue == 'All' || this.contains(marker.company.name, partnerValue))) {
                marker.setVisible(true)
                // Extend bounds
                bounds.extend(marker.position)
                visible = true
            } else {
                marker.setVisible(false)
            }
        })
        if(!visible) {
            var bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(51.0134813, -4.43702817),
                new google.maps.LatLng(42.4939796, 7.837044)
            )
        }
        // Fit bounds
        this.map.fitBounds(bounds)
        // Repaint markerClusterers
        this.markerClusterers.forEach(markerClusterer => {
            markerClusterer.repaint()
        })
    }
    contains(str, sub) {
        const normalizedSub = sub.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
        const normalizedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()

        if(normalizedStr.includes(normalizedSub)) {
            return true
        } else {
            return false
        }
    }
    clearMap() {
        // Clear markers
        this.markers.forEach(marker => {
            marker.setMap(null)
        })
        this.markers = []
        // Clear markerer clusterers
        this.markerClusterers.forEach(markerClusterer => {
            markerClusterer.clearMarkers()
        })
        this.markerClusterers = []
    }
    createValues() {
        // Create departments values array
        document.querySelectorAll('datalist[id="departments"] option').forEach(option => {
            this.departments[option.innerHTML] = option.dataset.value
        })
    }
    submit() {
        // Submit event
        document.querySelector('.webform-button--submit-container input[type="submit"]').addEventListener('click', e => {
            e.preventDefault()
            // Loading style on submit button
            document.querySelector('.webform-button--submit-container').classList.add('webform-button--submit-container--loading')
            // Loading style on rows
            document.querySelector('.m-view-adherents_list__rows').classList.add('m-view-adherents_list__rows--loading')
            // Reset page counter
            this.moreEl.dataset.page = 0
            // Get api call
            const url = this.getURL()
            // Send request to get results
            this.appendDOM(url, true)
            // Filter map
            this.filterMap()
        })
    }
    appendDOM(url, reset = false) {
        // Ajax call
        const request = new XMLHttpRequest()
        request.open('GET', url)
        request.responseType = 'document'
        request.send()
        const _this = this
        request.onload = () => {
            const dom = request.response
            // Get rows
            const rows = dom.querySelectorAll('.m-view-adherents_list__row')
            if(reset) {
                // FILTERED : Replace current rows
                document.querySelector('.m-view-adherents_list__rows').innerHTML = ''
                rows.forEach(row => {
                    document.querySelector('.m-view-adherents_list__rows').append(row)
                })
                // Remove loading style on submit button
            document.querySelector('.webform-button--submit-container').classList.remove('webform-button--submit-container--loading')
                // Remove loading style on rows
                document.querySelector('.m-view-adherents_list__rows').classList.remove('m-view-adherents_list__rows--loading')
                // Remove more link if there are less than pagination rows
                _this.hideMoreLink(document.querySelectorAll('.m-view-adherents_list__row').length)
            } else {
                // MORE LINK : Add to current rows
                rows.forEach(row => {
                    document.querySelector('.m-view-adherents_list__rows').append(row)
                })
                // Remove loading style on more link
                document.querySelector('.m-view-adherents_list__more').classList.remove('m-view-adherents_list__more--loading')
                // Remove more link if there are less than pagination rows
                _this.hideMoreLink(rows.length)
            }
        }
    }
    getURL(page = false) {
        // Get inputs value
        const department = document.querySelector('input[list="departments"]').value
        let departmentValue = department
        if(department == '') {
            departmentValue = 'All'
        } else if(this.departments[department]) {
            departmentValue = document.querySelector(`datalist[id="departments"] option[data-title="${department}"]`).dataset.value
        }
        const partnerValue = document.querySelector('input[list="names"]').value == '' ? 'All' : document.querySelector('input[list="names"]').value
        // return url
        return `/api/partners?department_id=${departmentValue}&name=${partnerValue}&page=${page ? page : 0}`
    }
    more() {
        this.moreEl.dataset.page = 0
        // Check if more link has to be on page load
        this.hideMoreLink(document.querySelectorAll('.m-view-adherents_list__row').length)
        // More event
        this.moreEl.addEventListener('click', e => {
            e.preventDefault()
            // Loading style on more link
            this.moreEl.classList.add('m-view-adherents_list__more--loading')
            // Get next page
            this.moreEl.dataset.page = parseInt(this.moreEl.dataset.page) + 1
            // Get api call
            const url = this.getURL(parseInt(this.moreEl.dataset.page))
            // Send request to get results
            this.appendDOM(url)
        })
    }
    hideMoreLink(length) {
        // Check if more link
        if(length < this.pagination) {
            document.querySelector('.m-view-adherents_list__more').classList.add('m-view-adherents_list__more--inactive')
        } else {
            document.querySelector('.m-view-adherents_list__more').classList.remove('m-view-adherents_list__more--inactive')
        }
    } 
    isLatitude(lat) {
        return isFinite(lat) && Math.abs(lat) <= 90
    }
    isLongitude(lng) {
        return isFinite(lng) && Math.abs(lng) <= 180
    }
}